function exitFullScreen(elements) {
	if (!elements) {
		elements = [document];
	} else if (elements.length === 0) {
		elements.push(document);
	}
	let fullscreenElementFound = false;
	let elementToClose;
	for (let i = 0; i < elements.length; i++) {
		const element = elements[i];

		if (!getFullScreenElement(element)) {
			continue;
		}
		fullscreenElementFound = true;
		elementToClose = element;
	}
	elementToClose = fullscreenElementFound ? elementToClose : document;
	closeElementFullScreen(elementToClose);
}

function closeElementFullScreen(element) {
	if (element.exitFullscreen) {
		element.exitFullscreen();
		return;
	}
	if (element.webkitExitFullscreen) {
		element.webkitExitFullscreen();
		return;
	}
	if (element.webkitCancelFullScreen) {
		element.webkitCancelFullScreen();
		return;
	}
	if (element.mozCancelFullScreen) {
		element.mozCancelFullScreen();
		return;
	}
	if (element.msExitFullscreen) {
		element.msExitFullscreen();
		return;
	}
}

function getFullScreenElement(element) {
	if (!element) {
		element = document;
	}

	return element.fullscreenElement ||
		element.mozFullScreenElement ||
		element.webkitFullscreenElement ||
		element.msFullscreenElement ||
		element.fullscreen ||
		element.mozFullScreen ||
		element.webkitIsFullScreen ||
		element.fullScreenMode;
}

function requestFullscreen(element, video, useNativeControls) {
	element = element === undefined ? document : element;

	if (video && useNativeControls) {
		element = video;
	}

	if (element.requestFullscreen) {
		element.requestFullscreen().catch(err => {
			console.err('Fullscreen request failed: ', err);
		});
	} else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen().catch(err => {
			console.err('Fullscreen request failed: ', err);
		});
	} else if (element.webkitRequestFullScreen) {
		element.webkitRequestFullScreen().catch(err => {
			console.err('Fullscreen request failed: ', err);
		});
	}
	// iOS fullScreen
	if (video && video.webkitEnterFullscreen) {
		video.webkitEnterFullscreen();
	}
}

export {exitFullScreen, getFullScreenElement, requestFullscreen};
